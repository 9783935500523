<template>
  <section class="hero is-fullheight" style="background-color: #d8ad51">
    <div class="container about">
      <div class="columns">
        <div class="column is-7">   
            <ProductZoomer
            :base-images="item.images"
            :base-zoomer-options="zoomerOptions"
          />
        </div>
        <div class="column is-5">
          <div class="content">
            <h1>{{ item.title }}</h1>
            <p>{{ item.description }}</p>
          </div>
          <!-- Ubah nomor Whatsapp -->
          <a
            href="http://wa.me/6285649174809"
            target="_blank"
            class="button is-success"
            >Pesan Melalui Whatsapp</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Detail",
  components: {},
  mounted() {
    axios
      .get("http://localhost:3000/artikel/" + this.$route.params.id)
      .then((res) => {
        this.item = res.data;
      });
  },
  data() {
    return {
      item : null,
      // images: {
      //   thumbs: [
      //     {
      //       id: 1,
      //       url: "https://source.unsplash.com/FEMmT5z7Fwg/150x150",
      //     },
      //     {
      //       id: 2,
      //       url: "https://source.unsplash.com/vUc03gxjEY4/150x150",
      //     },
      //     {
      //       id: 3,
      //       url: "https://source.unsplash.com/KYE_JosGGik/150x150",
      //     },
      //     {
      //       id: 4,
      //       url: "https://source.unsplash.com/9Jr9hUzRVhc/150x150",
      //     },
      //   ],
      //   normal_size: [
      //     {
      //       id: 1,
      //       url: "https://source.unsplash.com/FEMmT5z7Fwg/600x600",
      //     },
      //     {
      //       id: 2,
      //       url: "https://source.unsplash.com/vUc03gxjEY4/600x600",
      //     },
      //     {
      //       id: 3,
      //       url: "https://source.unsplash.com/KYE_JosGGik/600x600",
      //     },
      //     {
      //       id: 4,
      //       url: "https://source.unsplash.com/9Jr9hUzRVhc/600x600",
      //     },
      //   ],
      //   large_size: [
      //     {
      //       id: 1,
      //       url: "https://source.unsplash.com/FEMmT5z7Fwg/900x900",
      //     },
      //     {
      //       id: 2,
      //       url: "https://source.unsplash.com/vUc03gxjEY4/900x900",
      //     },
      //     {
      //       id: 3,
      //       url: "https://source.unsplash.com/KYE_JosGGik/900x900",
      //     },
      //     {
      //       id: 4,
      //       url: "https://source.unsplash.com/9Jr9hUzRVhc/900x900",
      //     },
      //   ],
      // },
      zoomerOptions: {
        zoomFactor: 3,
        pane: "pane",
        hoverDelay: 300,
        namespace: "zoomer-left",
        move_by_click: false,
        scroll_items: 4,
        choosed_thumb_border_color: "#dd2c00",
        scroller_position: "left",
        zoomer_pane_position: "right",
      },
    };
  },
  methods: {},
};
</script>
